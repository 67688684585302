@font-face {
    font-family: 'Playfair Display';
    src: url('./fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf') format('truetype'),
    url('./fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Trocchi';
    src: url('./fonts/Trocchi/Trocchi-Regular.ttf') format('truetype'),
    url('./fonts/Trocchi/Trocchi-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Martel';
    src: url('./fonts/Martel/Martel-Regular.ttf') format('truetype'),
    url('./fonts/Martel/Martel-Regular.ttf') format('truetype');
}


@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype'),
    url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Quantico';
    src: url('./fonts/Quantico/Quantico-Regular.ttf') format('truetype'),
    url('./fonts/Quantico/Quantico-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('./fonts/Source_Serif_Pro/SourceSerifPro-Regular.ttf') format('truetype'),
    url('./fonts/Source_Serif_Pro/SourceSerifPro-Regular.ttf') format('truetype');
}

.light-theme {
    --header1-color: #1FB4C9;
    --header2-color: #CC1356;
    --header3-color: #CC1356;
    --header4-color: #1FB4C9;
    --header5-color: #1FB4C9;
    --text-color: #090000;
    --name-hover: #7c0c35;
    --contact-logo-color: #1FB4C9;
    --contact-logo-hover: #157886;
    --icon-background: #2196F3FF;

    --card-header: #F7F7F7;
    --card-body: #FFFFFF;
    --background-color: #e7e7e7;
    --loading-overlay-color: lightsteelblue;
    --theme-toggler-color: rebeccapurple;
    --theme-toggler-hover: #220041;

    --parallax-image: url("./images/light_2.png");
}

.dark-theme {
    --header1-color: #E14B37;
    --header2-color: #33EDA8;
    --header3-color: #33EDA8;
    --header4-color: #DF690D;
    --header5-color: #DF690D;
    --text-color: #eaeaea;
    --name-hover: #24a979;
    --contact-logo-color: #E14B37;
    --contact-logo-hover: #982d1f;
    --icon-background: #2196F3FF;

    --card-header: #1f1d1f;
    --card-body: #010001;
    /*--background-color: #000101;*/
    --background-color: #101621;
    --loading-overlay-color: lightsteelblue;
    --theme-toggler-color: gold;
    --theme-toggler-hover: #ffedb0;

    --parallax-image: url("./images/dark_2.png");

}


.App, .background {
    text-align: center;
    background-color: var(--background-color);
}

.navbar {
    background-color: var(--card-header);
}

h1, .header1, .h1 {
    font-family: Roboto, sans-serif;
    color: var(--header1-color);

}

h2, .header2, .h2 {
    font-family: Quantico, sans-serif;
    color: var(--header2-color);
    font-size: 40px;

}


h3, .header3, .h3 {
    font-family: Quantico, sans-serif;
    color: var(--header3-color);
    font-size: 30px;

}


h4, .header4 {
    color: var(--header4-color);

}


h5, .header5 {
    color: var(--header5-color);

}


.contact-logo {
    color: var(--contact-logo-color);
}

.contact-logo:hover {
    color: var(--contact-logo-hover);
    border-bottom-color: var(--contact-logo-hover);
    border-bottom-width: 3px;
    border-bottom-style: solid;
}

p, .p {
    font-family: "Source Serif Pro", serif;
    color: var(--text-color);
}

.section-header {
    margin-bottom: 50px;
    text-decoration-line: underline;
}

.name {
    font-family: Quantico, sans-serif;
    color: var(--header2-color);

}

.name:hover {
    font-family: Quantico, sans-serif;
    color: var(--name-hover);

}

.nav-link {

}

.nav-link:hover {
    color: var(--name-hover);
    text-decoration-line: underline;
}

.intro {
    padding: 10px;
    margin: 50px;
    font-size: 60px;
    font-weight: bold;
}

.about {
    width: 40%;

    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 18px;

}

@media screen {

    .about {
        width: 80%;
    }

}


.tag {
    margin: 5px;
}


.spacer {
    height: 40px;
}

.loading-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: lightsteelblue;
    opacity: 0.5;
    z-index: 10;
}

.btn-flat {
    background-color: purple;
    color: white;
}

.project-card-body {
    background-color: var(--card-body);
}

.project-card-header {
    background-color: var(--card-header);
}

.project-card-footer {
    background-color: var(--card-header);

}


.theme-toggler {
    background-color: var(--theme-toggler-color);
}

.theme-toggler:hover {
    background-color: var(--theme-toggler-hover);
}

.float-in-right {
    animation: .5s ease-out 0s 1 slideInFromRight;

}


.float-in-left {
    animation: .5s ease-out 0s 1 slideInFromLeft;

}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-50%);
    }
    80% {
        transform: translateX(1%);
    }
    100% {
        transform: translateX(0);
    }
}


@keyframes slideInFromRight {
    0% {
        transform: translateX(50%);
    }
    80% {
        transform: translateX(-1%);
    }
    100% {
        transform: translateX(0);
    }
}


@keyframes appear {
    0% {
        opacity: 10%;
    }

    100% {
        opacity: 100%;
    }
}


.parallax {
    background-image: var(--parallax-image);
    min-height: 90%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;

}


@media only screen and (max-width: 720px) {
    .project-card-image {
        display: none;
    }
}

.vertical-timeline-element-icon {

    -webkit-box-shadow: none;
    box-shadow: none;
}


.timeline-icon {
    background: var(--icon-background);
    color: var(--card-body);
}
